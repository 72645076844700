<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('damReport.monthly_average_price_list_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <b-row>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Division" vid="division_id" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="division_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('globalTrans.division') }} <span class="text-danger">*</span>
                              </template>
                              <vue-multi-select
                                id="division_id"
                                v-model="values_division"
                                search
                                historyButton
                                :filters="filters_division"
                                :options="options"
                                :selectOptions="divisionList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                class="w-100"
                                :btnLabel="btnLabel"
                                btnClass="w-100 d-block text-left"
                                />
                              <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                      <ValidationProvider name="District" vid="district_id" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="district_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('globalTrans.district') }} <span class="text-danger">*</span>
                              </template>
                              <vue-multi-select
                                id="district_id"
                                v-model="values_district"
                                search
                                historyButton
                                :filters="filters_district"
                                :options="options"
                                :selectOptions="districtList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                class="w-100"
                                :btnLabel="btnLabel"
                                btnClass="w-100 d-block text-left"
                                />
                              <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                    <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Upazila" vid="upazila_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="upazila_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.upazila') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                id="upazila_id"
                                v-model="values_upazila"
                                search
                                historyButton
                                :filters="filters_upazila"
                                :options="options"
                                :selectOptions="upazilaList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                class="w-100"
                                :btnLabel="btnLabel"
                                btnClass="w-100 d-block text-left"
                                />
                                <div class="invalid-feedback d-block"   v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Market" vid="market_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="market_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('crop_price_config.market') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                id="market_id"
                                v-model="values_market"
                                search
                                historyButton
                                :filters="filters_market"
                                :options="options"
                                :selectOptions="marketList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                class="w-100"
                                :btnLabel="btnLabel"
                                btnClass="w-100 d-block text-left"
                                />
                                <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Commodity Group" vid="commodity_group_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="commodity_group_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('crop_price_config.commodity_group_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.commodity_group_id"
                                :options="groupList"
                                id="commodity_group_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                       </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Commodity Sub Group" vid="commodity_sub_group" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="commodity_sub_group_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('crop_price_config.commodity_sub_group') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.commodity_sub_group_id"
                                :options="subgroupList"
                                id="commodity_sub_group_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Commodity Name" vid="commodity_name" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="commodity_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('crop_price_config.commodity_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.commodity_id"
                                :options="commodityNameList"
                                id="commodity_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                      <ValidationProvider name="From Year" vid="year_from" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="year_from"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('globalTrans.yearFrom') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.year_from"
                                :options="yearList"
                                id="year_from"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                      <ValidationProvider name="To Year" vid="yearTo" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="year_to"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('globalTrans.yearTo') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.year_to"
                                :options="yearList"
                                id="year_to"
                                 :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                             <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2" @click="clickSubmit=true">{{ $t('globalTrans.search') }}</b-button>
                        &nbsp;
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay :show="loading">
              <iq-card v-if='datas.length'>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('damReport.monthly_average_price_list_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <export-excel
                            class="btn btn_add_new"
                            :data="excelData"
                            :title="headerValue"
                            worksheet="Report Sheet"
                            name="monthly-average-price-report.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="warehouseServiceBaseUrl" uri="/master-warehouse-report-heading/detail" :org-id="13">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('damReport.monthly_average_price_list_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <div class="text-center">
                                        <table style="width:100%;color:black;">
                                          <tr>
                                            <td class="align-top" align="right" width="20%">{{ $t('globalTrans.division') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" width="75%" >{{ getDivisionName(formData.division_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('globalTrans.district') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" >{{ getDistrictName(formData.district_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('globalTrans.upazila') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" >{{ getUpazilaNameCustom(formData.upazila_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.market') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" >{{ getMarketName(formData.market_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.commodity_group_name') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left" style="width:10%">{{ ($i18n.locale==='bn') ? formData.commodity_group_name_bn : formData.commodity_group_name_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.commodity_sub_group') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? formData.commodity_sub_group_name_bn : formData.commodity_sub_group_name_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.commodity_name') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? formData.commodity_name_bn : formData.commodity_name_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('globalTrans.yearFrom') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? formData.year_from_bn : formData.year_from_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('globalTrans.yearTo') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? formData.year_to_bn : formData.year_to_en }}</td>
                                          </tr>
                                        </table>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <!-- <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                                        <b-thead>
                                          <b-tr>
                                            <b-th style="width:10%" class="text-center">{{ $t('globalTrans.year') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('globalTrans.month') }}</b-th>
                                            <b-th style="width:20%" class="text-center">{{ $t('damReport.product_name_and_description') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.measurement') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.average_retail_price') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.measurement') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.average_wholesale_price') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in datas" :key="index">
                                            <b-td class="text-center">{{ $n(info.year, { useGrouping: false }) }}</b-td>
                                            <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.month_bn : info.month_en }}</b-td>
                                            <b-td class="text-center">{{ ($i18n.locale === 'bn') ? info.commodity_name_bn : info.commodity_name }}</b-td>
                                            <b-td class="text-center">{{ ($i18n.locale === 'bn') ? info.commodity.unit_retail.unit_name_bn : info.commodity.unit_retail.unit_name }} </b-td>
                                            <b-td class="text-center">{{ $n(parseFloat(info.a_r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(info.a_r_howestPrice).toFixed(2)) }}</b-td>
                                              <b-td class="text-center">{{ ($i18n.locale === 'bn') ? info.commodity.unit_whole_sale.unit_name_bn : info.commodity.unit_whole_sale.unit_name }}</b-td>
                                              <b-td class="text-center">{{ $n(parseFloat(info.a_w_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(info.a_w_highestPrice).toFixed(2)) }}</b-td>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row> -->
                                  <b-row>
                                    <b-col sm="12" class="steaky-header">
                                      <slot>
                                        <b-table head-variant="primary" :sticky-header="stickyHeader" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="datas" :fields="columns">
                                          <template v-slot:cell(a_r_lowestPrice)="data">
                                            {{ parseFloat(data.item.a_r_lowestPrice) || parseFloat(data.item.a_r_lowestPrice) ? $n(parseFloat(data.item.a_r_lowestPrice).toFixed(2)) + '-' + $n(parseFloat(data.item.a_r_howestPrice).toFixed(2)) : '' }}
                                          </template>
                                          <template v-slot:cell(a_w_lowestPrice)="data">
                                            {{ parseFloat(data.item.a_w_lowestPrice) || parseFloat(data.item.a_w_highestPrice) ? $n(parseFloat(data.item.a_w_lowestPrice).toFixed(2)) + '-' + $n(parseFloat(data.item.a_w_highestPrice).toFixed(2)) : '' }}
                                          </template>
                                          <template v-slot:cell(year)="data">
                                            {{ $n(data.item.year, { useGrouping: false }) }}
                                          </template>
                                        </b-table>
                                      </slot>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import Store from '@/store'
import moment from 'moment'
import excel from 'vue-excel-export'
import Vue from 'vue'
import vueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead,
        vueMultiSelect
    },
    created () {
        // if (this.id) {
        //     const tmp = this.getEditingData()
        //     this.formData = tmp
        // }
    },
    data () {
        return {
          btnLabel: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
          AveragePriceText: '',
          maxPriceMsg: '',
          minPriceMsg: '',
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          formData: {
              division_id: [],
              district_id: [],
              upazila_id: [],
              market_id: [],
              commodity_group_id: 0,
              commodity_sub_group_id: 0,
              commodity_id: 0,
              year_from: 0,
              year_to: 0
          },
            stickyHeader: true,
            headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          currentDate: '',
          listItemofyerterday: '',
          districtList: [],
          upazilaList: [],
          marketList: [],
          subgroupList: [],
          commoditySubGroupList: [],
          commodityNameList: [],
          warehouseServiceBaseUrl: warehouseServiceBaseUrl,
          datas: [],
          showData: false,
          values_division: [],
          values_district: [],
          values_upazila: [],
          values_market: [],
          filters_division: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          filters_district: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          filters_upazila: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          filters_market: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          options: {
            multi: true,
            groups: false,
            labelName: 'text',
            labelValue: 'value',
            cssSelected: option => (option.selected ? { 'background-color': '#5764c6' } : '')
          },
          clickSubmit: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
        this.AveragePriceText = (parseInt(this.formData.HighestPrice) + parseInt(this.formData.LowestPrice)) / 2
    },
    computed: {
      headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
          headerVal[0] = this.headerExcelDefault.orgName
          headerVal[1] = this.headerExcelDefault.address
          headerVal[2] = this.$t('damReport.monthly_average_price_list_report')
          headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.formData.division_id)
          headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.formData.district_id)
          headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.getUpazilaNameCustom(this.formData.upazila_id)
          headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.getMarketName(this.formData.market_id)
          headerVal[7] = this.$t('crop_price_config.commodity_group') + ':  ' + this.formData.commodity_group_name_en
          headerVal[8] = this.$t('crop_price_config.commodity_sub_group') + ': ' + this.formData.commodity_sub_group_name_en
          headerVal[9] = this.$t('crop_price_config.commodity_name') + ' : ' + this.formData.commodity_name_en
          headerVal[10] = this.$t('globalTrans.yearFrom') + ':  ' + this.formData.year_from_en
          headerVal[11] = this.$t('globalTrans.yearTo') + ': ' + this.formData.year_to_en
        } else {
          headerVal[0] = this.headerExcelDefault.orgNameBn
          headerVal[1] = this.headerExcelDefault.address_bn
          headerVal[2] = this.$t('damReport.monthly_average_price_list_report')
          headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.formData.division_id)
          headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.formData.district_id)
          headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.getUpazilaNameCustom(this.formData.upazila_id)
          headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.getMarketName(this.formData.market_id)
          headerVal[7] = this.$t('crop_price_config.commodity_group') + ':  ' + this.formData.commodity_group_name_bn
          headerVal[8] = this.$t('crop_price_config.commodity_sub_group') + ': ' + this.formData.commodity_sub_group_name_bn
          headerVal[9] = this.$t('crop_price_config.commodity_name') + ' : ' + this.formData.commodity_name_bn
          headerVal[10] = this.$t('globalTrans.yearFrom') + ':  ' + this.formData.year_from_bn
          headerVal[11] = this.$t('globalTrans.yearTo') + ': ' + this.formData.year_to_bn
        }
        return headerVal
      },
      excelData: function () {
        const listData = this.datas
        var serial = 0
        const listContractor = listData.map(info => {
          serial++
          if (this.$i18n.locale === 'en') {
              return {
                'Sl ': serial,
                'Year ': info.year,
                'Month ': info.month_en,
                'Product name and description': info.commodity_name,
                'Retail Measurement ': info.unit_name,
                'Average Retail Price': parseFloat(info.a_r_lowestPrice) || parseFloat(info.a_r_howestPrice) ? parseFloat(info.a_r_lowestPrice).toFixed(2) + '--' + parseFloat(info.a_r_howestPrice).toFixed(2) : ' ',
                'Wholesale Measurement ': info.w_unit_name,
                'Average Wholesale Price': parseFloat(info.a_w_lowestPrice) || parseFloat(info.a_w_highestPrice) ? parseFloat(info.a_w_lowestPrice).toFixed(2) + '--' + parseFloat(info.a_w_highestPrice).toFixed(2) : ' '
              }
            } else {
              return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                'বছর ': this.$n(info.year, { useGrouping: false }),
                'মাস ': info.month_bn,
                'পণ্যের নাম ও বিবরণ': info.commodity_name_bn,
                'খুচরা একক ': info.unit_name_bn,
                'গড় পাইকারী দর': parseFloat(info.a_r_lowestPrice) || parseFloat(info.a_r_howestPrice) ? this.$n(parseFloat(info.a_r_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(info.a_r_howestPrice).toFixed(2)) : ' ',
                'পাইকারী একক ': info.w_unit_name_bn,
                'গড় পাইকারী দর ': parseFloat(info.a_w_lowestPrice) || parseFloat(info.a_w_highestPrice) ? this.$n(parseFloat(info.a_w_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(info.a_w_highestPrice).toFixed(2)) : ' '
              }
            }
        })
        return listContractor
      },
        monthList: function () {
            return this.$store.state.Portal.agriObj.monthList
        },
        weekList: function () {
          return this.$store.state.Portal.agriObj.weekList
        },
        divisionList: function () {
          return this.$store.state.Portal.agriObj.divisionList
        },
        groupList () {
          return this.$store.state.Portal.agriObj.commodityGroupList.filter(item => item.status === 1)
        },
        yearList () {
          const yearList = this.$store.state.Portal.agriObj.yearList
          return yearList.map(item => {
            if (this.$i18n.locale === 'en') {
              return { value: item.value, text: item.text_en }
            } else {
              return { value: item.value, text: item.text_bn }
            }
          })
        },
        priceEntryTypeList: function () {
          return this.$store.state.Portal.agriObj.priceEntryTypeList
        },
        timePreiodList: function () {
          return this.$store.state.Portal.agriObj.timePreiodList
        },
        i18 () {
          return this.$i18n.locale
        },
        priceTypeList: function () {
          return this.$store.state.Portal.agriObj.priceTypeList
        },
        columns () {
          const labels = [
              { label: this.$t('globalTrans.year'), class: 'text-center', sortable: true },
              { label: this.$t('crop_price_config.month'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.product_name_and_description'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.measurement'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.average_retail_price'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.measurement'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.average_wholesale_price'), class: 'text-center', sortable: true }
            ]
          let keys = []

          if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'year' },
              { key: 'month_bn' },
              { key: 'commodity_name_bn' },
              { key: 'unit_name_bn' },
              { key: 'a_r_lowestPrice' },
              { key: 'w_unit_name_bn' },
              { key: 'a_w_lowestPrice' }
            ]
          } else {
            keys = [
              { key: 'year' },
              { key: 'month_en' },
              { key: 'commodity_name' },
              { key: 'unit_name' },
              { key: 'a_r_lowestPrice' },
              { key: 'w_unit_name' },
              { key: 'a_w_lowestPrice' }
            ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
        },
        loading () {
          return this.$store.state.commonObj.loading
        }
    },
    watch: {
        values_division: function (newVal) {
          if (newVal.length) {
            this.districtList = this.getDistrictList(newVal)
          } else {
            this.districtList = []
          }
        },
        values_district: function (newVal) {
          if (newVal.length) {
            this.upazilaList = this.getUpazilaList(newVal)
          } else {
            this.upazilaList = []
          }
        },
        values_upazila: function (newVal) {
          if (newVal.length) {
            this.marketList = this.getMarketList(newVal)
          } else {
            this.marketList = []
          }
        },
        'formData.commodity_group_id': function (newVal, oldVal) {
            this.subgroupList = this.getSubGroupList(newVal)
        },
        'formData.commodity_sub_group_id': function (newVal, oldVal) {
            this.commodityNameList = this.getCommodityNameList(newVal)
        }
    },
    methods: {
      monthCheck (value) {
        // if (this.$i18n.locale === 'bn') {
        //   var bengali = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
        //   return bengali.indexOf(moment(value).format('M'))
        // } else {
          return moment(value).format('M')
        // }
      },
      pdfExport () {
          const reportTitle = this.$t('damReport.monthly_average_price_list_report')
          ExportPdf.exportPdfDetails(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, this.datas, this, this.formData)
        },
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        headerDataExcel () {
          RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
            if (response.success) {
              const orgList = this.$store.state.Portal.agriObj.organizationProfileList.find(item => item.value === 13)
              const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
              const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
              this.headerExcelDefault.orgName = orgName
              this.headerExcelDefault.orgNameBn = orgNameBn
              this.headerExcelDefault.address = response.data.address
              this.headerExcelDefault.address_bn = response.data.address_bn
            }
          })
        },
        async saveData () {
          Store.commit('mutateCommonProperties', {
            loading: true
          })
          this.showData = true

          const commodityGroupListObj = this.$store.state.Portal.agriObj.commodityGroupList.find(item => item.value === this.formData.commodity_group_id)
          this.formData.commodity_group_name_en = typeof commodityGroupListObj !== 'undefined' ? commodityGroupListObj.text_en : ''
          this.formData.commodity_group_name_bn = typeof commodityGroupListObj !== 'undefined' ? commodityGroupListObj.text_bn : ''

          const commoditySubGroupListObj = this.$store.state.Portal.agriObj.commoditySubGroupList.find(item => item.value === this.formData.commodity_sub_group_id)
          this.formData.commodity_sub_group_name_en = typeof commoditySubGroupListObj !== 'undefined' ? commoditySubGroupListObj.text_en : ''
          this.formData.commodity_sub_group_name_bn = typeof commoditySubGroupListObj !== 'undefined' ? commoditySubGroupListObj.text_bn : ''

          const commodityNameListObj = this.$store.state.Portal.agriObj.commodityNameList.find(item => item.value === this.formData.commodity_id)
          this.formData.commodity_name_en = typeof commodityNameListObj !== 'undefined' ? commodityNameListObj.text_en : ''
          this.formData.commodity_name_bn = typeof commodityNameListObj !== 'undefined' ? commodityNameListObj.text_bn : ''

          const yearFromListObj = this.$store.state.Portal.agriObj.yearList.find(item => item.value === this.formData.year_from)
          this.formData.year_from_en = typeof yearFromListObj !== 'undefined' ? yearFromListObj.text_en : ''
          this.formData.year_from_bn = typeof yearFromListObj !== 'undefined' ? yearFromListObj.text_bn : ''

          const yearToListObj = this.$store.state.Portal.agriObj.yearList.find(item => item.value === this.formData.year_to)
          this.formData.year_to_en = typeof yearToListObj !== 'undefined' ? yearToListObj.text_en : ''
          this.formData.year_to_bn = typeof yearToListObj !== 'undefined' ? yearToListObj.text_bn : ''

          const marketIds = []
          this.values_market.forEach(item => {
            marketIds.push(parseInt(item.value))
          })
          this.formData.market_id = marketIds
          const result = await RestApi.postData(agriMarketingServiceBaseUrl, '/crop-price-info/reports/price-report/market-monthly-price-report', this.formData)
          if (result.success) {
            Store.commit('mutateCommonProperties', {
              loading: false
            })
            this.datas = []
            if (result.data) {
              this.datas = this.getRelationalData(result.data)
              this.clickSubmit = false
            } else {
              this.datas = []
              Store.commit('mutateCommonProperties', {
                loading: false
              })
              this.clickSubmit = false
            }
          }
          this.headerDataExcel()
        },
        getRelationalData (data) {
          const commodityNameList = this.$store.state.Portal.agriObj.commodityNameList
          const list = data.map(item => {
            const commodityObj = commodityNameList.find(com => com.value === item.commodity_id)
            const rUnitObj = this.$store.state.Portal.agriObj.measurementUnitList.find(unit => unit.value === item.unit_retail)
            const wUnitObj = this.$store.state.Portal.agriObj.measurementUnitList.find(unit => unit.value === item.unit_whole_sale)
            const yearData = item.price_date.split('-')[0]
            const monthData = this.monthCheck(item.price_date)
            const monthListObj = this.$store.state.Portal.agriObj.monthList.find(item => item.value === parseInt(monthData))
            const commodityData = {
              commodity_name: commodityObj !== 'undefined' ? commodityObj.text_en : '',
              commodity_name_bn: commodityObj !== 'undefined' ? commodityObj.text_bn : '',
              year: yearData !== null || yearData !== 'undefined' ? yearData : '',
              month_en: typeof monthListObj !== 'undefined' ? monthListObj.text_en : '',
              month_bn: typeof monthListObj !== 'undefined' ? monthListObj.text_bn : ''
            }
            const rUnitData = {
              unit_name: typeof rUnitObj !== 'undefined' ? rUnitObj.text_en : '',
              unit_name_bn: typeof rUnitObj !== 'undefined' ? rUnitObj.text_bn : ''
            }
            const wUnitData = {
              w_unit_name: typeof wUnitObj !== 'undefined' ? wUnitObj.text_en : '',
              w_unit_name_bn: typeof wUnitObj !== 'undefined' ? wUnitObj.text_bn : ''
            }
            return Object.assign({}, item, commodityData, wUnitData, rUnitData)
          })
          return list
        },
        getDivisionName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getDistrictList (divisionList) {
          const divisionIds = []
          divisionList.forEach(item => {
            divisionIds.push(parseInt(item.value))
          })
          this.formData.division_id = divisionIds
          this.districtList = this.$store.state.Portal.agriObj.districtList.filter(item => divisionIds.includes(item.division_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
          })
          if (this.values_district.length) {
            const arr = []
            this.values_district.forEach(item => {
              const obj = this.districtList.find(obj => obj.value === parseInt(item.value))
              if (obj !== undefined) {
                arr.push(item)
              }
            })
            this.values_district = arr
          }
          return this.districtList
        },
        getDistrictName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.districtList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.districtList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getUpazilaList (districtList) {
          const districtIds = []
          districtList.forEach(item => {
            districtIds.push(parseInt(item.value))
          })
          this.formData.district_id = districtIds
          this.upazilaList = this.$store.state.Portal.agriObj.upazilaList.filter(item => districtIds.includes(item.district_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
          })

          if (this.values_upazila.length) {
            const arr = []
            this.values_upazila.forEach(item => {
              const obj = this.upazilaList.find(obj => obj.value === parseInt(item.value))
              if (obj !== undefined) {
                arr.push(item)
              }
            })
            this.values_upazila = arr
          }
          return this.upazilaList
        },
        getUpazilaName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getUpazilaNameCustom (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const market = this.$store.state.Portal.agriObj.marketList.find(item => item.upazila_id === parseInt(id))
              if (market !== undefined) {
                const obj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === parseInt(id))
                arr.push(obj?.text)
              }
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getMarketList (upazilaList) {
          const upazilaIds = []
          upazilaList.forEach(item => {
            upazilaIds.push(parseInt(item.value))
          })
          this.formData.upazila_id = upazilaIds
          this.marketList = this.$store.state.Portal.agriObj.marketList.filter(item => upazilaIds.includes(item.upazila_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
          })

          if (this.values_market.length) {
            const arr = []
            this.values_market.forEach(item => {
              const obj = this.upazilaList.find(obj => obj.value === parseInt(item.value))
              if (obj !== undefined) {
                arr.push(item)
              }
            })
            this.values_market = arr
          }
          return this.marketList
        },
        getMarketName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.marketList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.marketList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getNames (list) {
          const arr = []
          list.forEach(item => {
            arr.push(item?.text)
          })
          const str = arr.join(', ')
          return str.length > 50 ? str.substring(0, 40) + '..' : str
        },
        getPriceTypeList: function (entryType) {
            const dataList = this.$store.state.Portal.agriObj.priceTypeList.filter(item => item.entry_type === entryType)
            return dataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        getSubGroupList (groupId = null) {
            const subgroupList = this.$store.state.Portal.agriObj.commoditySubGroupList.filter(item => item.status === 1)

            if (groupId) {
                return subgroupList.filter(subgroup => subgroup.commodity_group_id === groupId)
            }
            return subgroupList
        },
        getCommodityNameList (groupId = null) {
            const commodityNameList = this.$store.state.Portal.agriObj.commodityNameList.filter(item => item.status === 1)

            if (groupId) {
                return commodityNameList.filter(commodityName => commodityName.commodity_sub_group_id === groupId)
            }
            return commodityNameList
        }
    }
}
</script>
